import React, { useState } from 'react';
import styled from 'styled-components';
import Logo from '../assets/images/logo.inline.svg';
import HeaderMenu from './HeaderMenu';

const HeaderContainer = styled.header`
  height: 116px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 100px;

  .menu-icon {
    display: none;
  }

  @media only screen and (max-width: 576px) {
    padding: 32px;

    .menu-icon {
      display: inline-block;
      padding: 8px 12px 6px 12px;
      border: 1px solid;
      border-color: ${({ isMenuOpen, theme }) =>
        isMenuOpen ? theme.colors.light2 : 'rgba(0, 0, 0, 0.3)'};
      border-radius: 6px;
      z-index: 1001;
      color: ${({ isMenuOpen, theme }) =>
        isMenuOpen ? theme.colors.light2 : theme.colors.text};
    }
  }
`;

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <HeaderContainer isMenuOpen={isMenuOpen}>
      <span className="brand-logo">
        <a href="/">
          <Logo />
        </a>
      </span>

      <span
        role="button"
        className="menu-icon"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        onKeyDown={() => setIsMenuOpen(!isMenuOpen)}
        tabIndex={0}
      >
        ☰
      </span>
      <HeaderMenu
        menuItems={[
          { name: 'Home', url: '/' },
          { name: 'Apps', url: '/#apps' },
          { name: 'Contact', url: '/contact/' },
        ]}
        isMenuOpen={isMenuOpen}
        toggleMenu={setIsMenuOpen}
      />
    </HeaderContainer>
  );
};

export default Header;
