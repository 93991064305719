import React from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';

import Box from './Box';
import Text from './Text';
import Link from './Link';

const HeroContainer = styled.div`
  width: 100%;
  min-height: 560px;
  height: 100%;
  padding: 0 100px;
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: 768px) {
    min-height: 100%;
    background: none;
    padding: 32px;
  }

  .hero-img {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
  }
`;

const HeroContentQuoraPixel = () => (
  <HeroContainer>
    <Box
      width={[1, 1, 1, 2 / 5]}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-start"
      height="100%"
    >
      <Text variant="h1" color="light2">
        Simple, hassle-free, easy to use Quora Pixel app that allows you to
        track customers each and every activity.
      </Text>
      <Link
        to="https://apps.shopify.com/sapp-quora-pixel"
        variant="button"
        mt={4}
      >
        Install Now
      </Link>
    </Box>
    <Box
      width={[1, 1, 1, 3 / 5]}
      display="flex"
      justifyContent="center"
      alignItems="center"
      p={[0, 0, 0, 5]}
      mt={[4, 4, 4, 0]}
    >
      <ReactPlayer
        url="https://www.youtube.com/watch?v=riDRk4pDP4g"
        className="video-container"
        width="100%"
        height="100%"
      />
    </Box>
  </HeroContainer>
);

export default HeroContentQuoraPixel;
