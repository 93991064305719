import React from "react"
import styled from "styled-components"
import { color } from "styled-system"

const ButtonBase = styled.button`
  padding: 16px 32px 10px 32px;
  border-radius: 6px;
  outline: none;
  border: none;
  text-transform: uppercase;
  background: ${({ theme }) => theme.colors.light2};
  color: ${({ theme }) => theme.colors.dark2};
  cursor: pointer;

  ${color};
`

const PrimaryButton = styled(ButtonBase)`
  background: ${({ theme }) => theme.colors.accent};
  color: ${({ theme }) => theme.colors.light2};
  ${color};
`

const Button = ({ variant, theme, children, ...others }) => {
  switch (variant) {
    case "primary":
      return (
        <PrimaryButton theme={theme} {...others}>
          {children}
        </PrimaryButton>
      )
    default:
      return (
        <ButtonBase theme={theme} {...others}>
          {children}
        </ButtonBase>
      )
  }
}

export default Button
