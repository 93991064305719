import React from 'react';
import Box from './Box';
import Link from './Link';
import Text from './Text';

const AppSummaryCard = ({ appName, summary, AppLogo, url }) => (
  <Box bg="light2" p={[4, 5]} textAlign="center">
    <AppLogo width="200" />
    <Text variant="h3" my={3}>
      {appName}
    </Text>
    <Text pb="32px">{summary}</Text>
    <Link variant="button" to={url} bg="accent" color="light2">
      Learn More
    </Link>
  </Box>
);

export default AppSummaryCard;
