import React from 'react';
import Box from './Box';
import Text from './Text';

const AppFeatureCard = ({ title, AppLogo }) => (
  <Box bg="light2" p={[4, 5]} textAlign="center">
    <AppLogo />
    <Text variant="h3" my={3}>
      {title}
    </Text>
  </Box>
);

export default AppFeatureCard;
