import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Link from './Link';

import { isBrowser } from '../config';

const MenuContainer = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: inline;
    margin-right: 40px;

    a {
      font-size: 16px;
      font-weight: 600;
      font-family: 'Montserrat', sans-serif;
      color: ${({ theme }) => theme.colors.text};

      &.active {
        color: ${({ theme }) => theme.colors.accent};
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @media only screen and (max-width: 576px) {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: ${({ theme }) => theme.colors.primary};
    z-index: 1000;
    display: ${({ isMenuOpen }) => (isMenuOpen ? 'block' : 'none')};
    padding-top: 100px;

    li {
      display: block;
      margin: 0;

      a {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 16px 0;
        color: ${({ theme }) => theme.colors.light2};
        opacity: 0.8;
        font-size: 20px;
        text-transform: uppercase;
        font-family: 'Montserrat', sans-serif;

        &.active {
          opacity: 1;
          color: ${({ theme }) => theme.colors.light2};
        }
      }
    }
  }
`;

const HeaderMenu = ({ menuItems = [], isMenuOpen = false, toggleMenu }) => {
  const [activeLink, setActiveLink] = useState(0);

  useEffect(() => {
    if (isBrowser && window.location.href.includes('/apps/')) {
      setActiveLink(1);
    }
    if (isBrowser && window.location.href.includes('/contact/')) {
      setActiveLink(2);
    }
  }, []);

  return (
    <MenuContainer isMenuOpen={isMenuOpen}>
      {menuItems.map((item, index) => (
        <li key={index}>
          <Link
            to={item.url}
            className={index === activeLink ? 'active' : ''}
            onClick={() => {
              toggleMenu(!isMenuOpen);
              return setActiveLink(index);
            }}
          >
            {item.name}
          </Link>
        </li>
      ))}
    </MenuContainer>
  );
};

export default HeaderMenu;
