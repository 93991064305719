import React from 'react';
import styled from 'styled-components';
import { color, space } from 'styled-system';
import { Link as URL } from 'gatsby';

const LinkBase = styled(URL)`
  color: ${({ theme }) => theme.colors.accent};
  text-decoration: none;

  ${color};
  ${space};
`;

const LinkButton = styled(LinkBase)`
  padding: 16px 32px 14px 32px;
  border-radius: 6px;
  outline: none;
  border: none;
  text-transform: uppercase;
  background: ${({ theme }) => theme.colors.light2};
  color: ${({ theme }) => theme.colors.dark2};
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;

  ${color};
  ${space};
`;

const Link = ({ variant, to, children, ...others }) => {
  switch (variant) {
    case 'button':
      return (
        <LinkButton to={to} {...others}>
          {children}
        </LinkButton>
      );
    default:
      return (
        <LinkBase to={to} {...others}>
          {children}
        </LinkBase>
      );
  }
};

export default Link;
