import styled from 'styled-components';
import {
  space,
  layout,
  position,
  color,
  typography,
  flexbox,
} from 'styled-system';

const InlineBox = styled.span`
  ${space};
  ${layout};
  ${position};
  ${color};
  ${typography};
  ${flexbox};
`;

export default InlineBox;
