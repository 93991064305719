import React from 'react';
import { ThemeProvider } from 'styled-components';

import * as themes from '../styles/themes';
import GlobalStyle from '../styles/GlobalStyles';
import { ThemeContext } from '../../ThemeProvider';
import { Header, HeroSection, Footer } from '../components';

const MainLayout = (props) => {
  const { children, heroContent } = props;

  return (
    <ThemeContext.Consumer>
      {(context = { theme: 'light' }) => {
        return (
          <ThemeProvider theme={themes['light']}>
            <GlobalStyle />
            <Header />
            {heroContent && <HeroSection>{heroContent}</HeroSection>}
            {children}
            <Footer />
          </ThemeProvider>
        );
      }}
    </ThemeContext.Consumer>
  );
};

export default MainLayout;
