import React from 'react';
import styled from 'styled-components';
import Text from './Text';
import Link from './Link';
import SappLogoSmall from '../assets/images/sapp-small.inline.svg';

const FooterContainer = styled.footer`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.light2};
  padding: 60px 100px;
  text-align: center;

  a {
    color: ${({ theme }) => theme.colors.text};
  }

  @media only screen and (max-width: 576px) {
    padding: 32px;
  }
`;

const Footer = () => (
  <FooterContainer>
    <Link to="/">
      <SappLogoSmall />
    </Link>
    <Text mt={3}>
      Copyright {new Date().getFullYear()} © - All Rights Reserved{' '}
      <a href="https://springrainit.com/">Spring Rain</a>.
    </Text>
    <Text>
      <Link to="/privacy-policy/">Privacy Policy</Link>
    </Text>
  </FooterContainer>
);

export default Footer;
