import React from 'react';
import styled from 'styled-components';
import Text from './Text';
import Link from './Link';

const PriceSectionContainer = styled.div`
  background-image: url('/assets/images/charge-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 218px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const PriceSection = ({ price = 10, appUrl = '' }) => (
  <PriceSectionContainer>
    <Text variant="h2" textAlign="center" color="light2">
      Monthly Charge {`$${price}.00`} USD{' '}
    </Text>
    <Link to={appUrl} variant="button" mt={4}>
      Install Now
    </Link>
  </PriceSectionContainer>
);

export default PriceSection;
