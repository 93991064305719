const colors = {
  primary: '#009EDA',
  accent: '#E88B2E',
  accent2: '#FBF2E9',
  light1: '#EEF4FA',
  light2: '#FFFFFF',
  dark1: '#040404',
  dark2: '#444444',
  dark3: '#3E4042',
};

export default colors;
