import styled from 'styled-components';
import { space, layout } from 'styled-system';

const Image = styled.img`
  width: 100%;
  height: auto;

  ${space};
  ${layout};
`;

export default Image;
