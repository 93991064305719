import React from 'react';
import styled from 'styled-components';
import Box from './Box';
import Text from './Text';
import Link from './Link';

const HeroContainer = styled.div`
  height: 100%;
  width: 100%;
  background-image: url('/assets/images/hero-bg-home.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 560px;
  padding: 0 100px;

  @media only screen and (max-width: 576px) {
    height: 100%;
    background: none;
    padding: 32px;
  }

  .hero-img {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
  }
`;

const HeroContentHome = () => (
  <HeroContainer>
    <Box
      width={[1, 1, 3 / 5, 2 / 5]}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-start"
      height="100%"
    >
      <Text variant="h1" color="light2">
        {'Grow your Business with SAPP marketing Apps'.toUpperCase()}
      </Text>
      <Text color="light2">
        Enhance your Shopify store with effective marketing tools that skyrocket
        your sales!
      </Text>

      <Link to="/#apps" variant="button" bg="light2" mt={3}>
        Our Apps
      </Link>
    </Box>
  </HeroContainer>
);

export default HeroContentHome;
