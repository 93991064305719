import colors from "../colors"

const breakpoints = ["576px", "768px", "992px", "1200px"]

breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

const lighTheme = {
  name: "light",
  colors: {
    ...colors,
    text: colors.dark2,
    background: colors.light2,
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  breakpoints,
}

export default lighTheme
