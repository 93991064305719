import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    color: ${({ theme }) => theme.colors.text};
    background-color: ${({ theme }) => theme.colors.background};
    font-size: 16px;
    line-height: 1.4;
    font-family: 'Roboto', sans-serif;

    @media only screen and (max-width: 576px) {
      font-size: calc(18px + (24 - 18) * ((100vw - 400px) / (1600 - 400)));
      line-height: calc(24px + 1.2vw);
    }

    p {
      margin: 0.5rem 0;
    }

    button {
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
      font-family: 'Montserrat', sans-serif;
    }

    img {
      width: 100%;
      height: auto;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 700;
      font-family: 'Montserrat', sans-serif;
      line-height: 1.1;
    }

    h1, h2, h3 {
      @media only screen and (max-width: 576px) {
        line-height: calc(32px + 2vw);
      }
    }

    h4, h5, h6 {
      line-height: calc(18px + .2vw);

      @media only screen and (max-width: 576px) {
        line-height: calc(20px + 1.2vw);
      }
    }

    h1 {
      font-size: 42px;
    }

    h2 {
      font-size: 36px;
    }

    h3 {
      font-size: 24px;
    }
    
    h5 {
      font-size: 18px;
    }

    a {
      color: ${({ theme }) => theme.colors.accent};
      text-decoration: none;
    }
  }


`;

export default GlobalStyle;
