import styled from 'styled-components';

const HeroSection = styled.div`
  height: 560px;
  background-color: ${({ theme }) => theme.colors.primary};

  @media only screen and (max-width: 768px) {
    height: 100%;
  }
`;

export default HeroSection;
